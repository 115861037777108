import $ from 'jquery';

import { $Elements, Breakpoints, Events } from '../global/constants';
import { disableBodyScroll, enableBodyScroll, loadExternalScript, parseFormData } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Forms ----------

	function handleFormModal() {

		const self = $(this);

		const form = self.find('form.main-form');
		const steps = form.find('.form-step');
		const previous = form.find('.form-navigation .previous');
		const next = form.find('.form-navigation .next');
		const submit = form.find('.form-navigation .submit');

		const form_content = self.find('.form-content');
		const form_planning = self.find('.form-planning');
		const form_done = self.find('.form-done');
		const form_success = form_done.find('.success');
		const form_error = form_done.find('.error');

		if(self.hasClass('appointment-modal')) {

			const planning_frame = form_planning.find('iframe');
			const frame_src = planning_frame.data('src');
			planning_frame.attr('src', frame_src);

			loadExternalScript('https://embed.acuityscheduling.com/js/embed.js');

			form_planning.removeClass('d-none');
		}
		else {

			const navigateTo = function(index) {

				const can_go_back = index === 0;
				const can_submit = index < steps.length - 1;

				steps.removeClass('current').eq(index).addClass('current');

				previous.attr('disabled', can_go_back);
				next.toggleClass('d-none', !can_submit);
				submit.toggleClass('d-none', can_submit);
			};

			const currentStep = function() {
				return steps.index(steps.filter('.current'));
			};

			previous.click(function(e) {
				e.preventDefault();
				navigateTo(currentStep() - 1);
			});

			next.click(function(e) {
				e.preventDefault();

				form.parsley().whenValidate({
					group: 'step-' + currentStep()
				}).done(function() {

					if(typeof dataLayer !== 'undefined' && currentStep() === 0) {

						dataLayer.push({
							'event': 'form_start',
							'form_id': self.data('form-id'),
							'form_type': self.data('form-type'),
							'cta_label': self.data('cta-label'),
							'cta_section_layout': self.data('cta-layout'),
							'cta_section_position': self.data('cta-position'),
							'cta_card_title': self.data('cta-title'),
						});
					}

					navigateTo(currentStep() + 1);
				});
			});

			self.each(handleForm);

			steps.each(function(index, step) {
				$(step).find(':input').attr('data-parsley-group', 'step-' + index);
			});

			navigateTo(0);

			self.on('hidden.bs.modal', function(e) {

				self.find(':input:not([type=hidden])').val('').removeClass('parsley-error parsley-success');

				navigateTo(0);

				form_content.removeClass('d-none');
				form_planning.addClass('d-none');
				form_done.addClass('d-none');
				form_success.addClass('d-none');
				form_error.addClass('d-none');
			});
		}
	}

	function handleForm() {

		const self = $(this);

		const form = self.find('form.main-form');
		const submit = form.find('.submit');

		const form_content = self.find('.form-content');
		const form_planning = self.find('.form-planning');
		const planning_frame = form_planning.find('iframe');
		const form_done = self.find('.form-done');
		const form_success = form_done.find('.success');
		const form_error = form_done.find('.error');

		form.parsley().on('form:submit', function() {

			if(typeof forms_submit_params !== 'undefined') {

				let data = parseFormData(form);
				data['page_url'] = window.location.origin + window.location.pathname;

				submit.attr('disabled', true);
				$Elements.LoadingBar.addClass('loading');

				window.onbeforeunload = function(e) {

					if(typeof forms_errors_params !== 'undefined') {

						$.ajax({
							url: forms_errors_params.url,
							type: 'POST',
							dataType: 'json',
							data: {
								action: forms_errors_params.action,
								nonce: forms_errors_params.nonce,
								logs: data,
							},
						});
					}

					return 'Closed before validation';
				};

				$.ajax({
					url: forms_submit_params.url,
					type: 'POST',
					dataType: 'json',
					data: {
						action: forms_submit_params.action,
						nonce: forms_submit_params.nonce,
						fields: data
					},
					success: function(response, status, jqXHR) {

						window.onbeforeunload = function(e) {
							return null;
						};

						submit.attr('disabled', false);
						$Elements.LoadingBar.removeClass('loading');
						form_content.addClass('d-none');

						if(response.success) {

							if(typeof dataLayer !== 'undefined') {

								dataLayer.push({
									'event': 'generate_lead',
									'form_id': self.data('form-id'),
									'form_type': self.data('form-type'),
									'cta_label': self.data('cta-label'),
									'cta_section_layout': self.data('cta-layout'),
									'cta_section_position': self.data('cta-position'),
									'cta_card_title': self.data('cta-title'),
								});
							}

							if(form_planning.length && planning_frame.length) {

								let frame_src = planning_frame.data('src');

								if(!data.telephone.startsWith('+')) {

									if(data.telephone.startsWith('0')) {
										data.telephone = data.telephone.substring(1);
									}

									data.telephone = '+33' + data.telephone;
								}

								if(frame_src.indexOf('zoho') !== -1) {

									frame_src += '?' + encodeURIComponent('Prénom') + '=' + encodeURIComponent(data.prenom);
									frame_src += '&' + encodeURIComponent('Nom') + '=' + encodeURIComponent(data.nom);
									frame_src += '&Email=' + encodeURIComponent(data.email);
									frame_src += '&' + encodeURIComponent('Téléphone') + '=' + encodeURIComponent(data.telephone);

									if(typeof data.utm_source !== 'undefined') {
										frame_src += '&utm_source=' + encodeURIComponent(data.utm_source);
									}

									if(typeof data.idcampaign !== 'undefined') {
										frame_src += '&idcampaign=' + encodeURIComponent(data.idcampaign);
									}

									planning_frame.attr('src', frame_src);
								}
								else {

									frame_src += '&firstName=' + encodeURIComponent(data.prenom);
									frame_src += '&lastName=' + encodeURIComponent(data.nom);
									frame_src += '&email=' + encodeURIComponent(data.email);
									frame_src += '&phone=' + encodeURIComponent(data.telephone);

									planning_frame.attr('src', frame_src);

									loadExternalScript('https://embed.acuityscheduling.com/js/embed.js');
								}

								form_planning.removeClass('d-none');
							}
							else {
								form_done.removeClass('d-none');
								form_success.removeClass('d-none');
							}
						}
						else {
							form_done.removeClass('d-none');
							form_error.removeClass('d-none');
							console.error(response.data);

							if(typeof forms_errors_params !== 'undefined') {

								$.ajax({
									url: forms_errors_params.url,
									type: 'POST',
									dataType: 'json',
									data: {
										action: forms_errors_params.action,
										nonce: forms_errors_params.nonce,
										logs: data,
										status: response.data.error,
									},
								});
							}
						}
					},
					error: function(jqXHR, status, errorThrown) {

						window.onbeforeunload = function(e) {
							return null;
						};

						submit.attr('disabled', false);
						$Elements.LoadingBar.removeClass('loading');
						form_content.addClass('d-none');
						form_done.removeClass('d-none');
						form_error.removeClass('d-none');
						console.error(jqXHR);

						if(typeof forms_errors_params !== 'undefined') {

							$.ajax({
								url: forms_errors_params.url,
								type: 'POST',
								dataType: 'json',
								data: {
									action: forms_errors_params.action,
									nonce: forms_errors_params.nonce,
									logs: data,
									status: jqXHR.status + ' ' + errorThrown,
								},
							});
						}
					}
				});
			}

			return false;
		});
	}

	$Elements.document.on('click', 'a[href*="#form-modal-"]', function(e) {
		e.preventDefault();

		let modal = $(this).attr('href');
		const hash = modal.substring(modal.indexOf('#'));
		$(hash).modal('show');

	});

	if($('body').hasClass('single-forms')) {
		$('.form-modal').modal();
		$('.form-modal:not(.typeform-form)').each(handleFormModal);
	}

	$('.landing-page-forms-toggle, [href="#landing-page-forms"]').click(function(e) {
		e.preventDefault();

		// todo check if the menu is open
		$('.landing-page-forms-toggle').toggleClass('opened');
		$('.landing-page-forms').toggleClass('active');
		//$('body').toggleClass('mobile-menu-open');
	});

	if(typeof forms_inline_params !== 'undefined') {

		$('.simple-form-section, .inline-form-section').each(function() {

			$.ajax({
				url: forms_inline_params.url,
				type: 'POST',
				dataType: 'json',
				data: {
					action: forms_inline_params.action,
					nonce: forms_inline_params.nonce,
					current: forms_inline_params.current,
					appointment: false,
				},
				success: (response, status, jqXHR) => {

					if(response.success) {
						$(this).find('form').prepend(response.data.fields);
						$(this).find('.planning').html(response.data.planning);
						$(this).each(handleForm);
					}
				}
			});
		});

		$('.appointment-modal').each(function() {

			$.ajax({
				url: forms_inline_params.url,
				type: 'POST',
				dataType: 'json',
				data: {
					action: forms_inline_params.action,
					nonce: forms_inline_params.nonce,
					current: forms_inline_params.current,
					appointment: true,
				},
				success: (response, status, jqXHR) => {

					if(response.success) {
						$(this).find('.planning').html(response.data.planning);
					}
				}
			});
		});
	}

	if(typeof forms_modals_params !== 'undefined') {

		let ids = [];

		$('[href*="#form-modal-"]').each(function() {
			const id = $(this).attr('href').split('#form-modal-')[1];
			if(ids.indexOf(id) === -1) {
				ids.push(id);
			}
		});

		// todo: check if data-target is used anywhere in the template files insted of data-bs-target
		$('[data-bs-target*="#form-modal-"]').each(function() {
			const id = $(this).attr('data-bs-target').split('#form-modal-')[1];
			if(ids.indexOf(id) === -1) {
				ids.push(id);
			}
		});

		$.ajax({
			url: forms_modals_params.url,
			type: 'POST',
			dataType: 'json',
			data: {
				action: forms_modals_params.action,
				nonce: forms_modals_params.nonce,
				current: forms_modals_params.current,
				planning: forms_modals_params.planning,
				forms: ids,
			},
			success: function(response, status, jqXHR) {

				if(response.success) {

					$('.landing-page-forms, .landing-page-forms-toggle').addClass('activable');

					if(window.innerWidth >= Breakpoints.sm && $Elements.body.hasClass('single-pages')) {
						$('.landing-page-forms-toggle').trigger('click');
					}

					$Elements.PageWrapper.append(response.data.modals);

					$('.form-modal:not(.typeform-form)').each(handleFormModal);

					$('.modal').on('show.bs.modal', disableBodyScroll);

					$('.modal').on('shown.bs.modal', function() {
						$('.landing-page-forms-toggle').removeClass('opened');
						$('.landing-page-forms').removeClass('active');
						$('body').removeClass('mobile-menu-open');
					});

					$('.modal').on('hidden.bs.modal', enableBodyScroll);
				}
			}
		});
	}
});
