import $ from 'jquery';
import { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, Swiper } from 'swiper';

import { $Elements, Breakpoints, Durations, Elements, Events } from './constants';

// ---------- Sections Position ----------

$('.content-wrapper > .content-section').each(function(index) {
	const mobile = Elements.ContentWrapper.querySelector('.mobile-intro');
	$(this).attr('data-position', (mobile) ? Math.max(0, index - 1) : index);
});

$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel ----------

	function get_carousel_offset(carousel, swiper) {

		$(carousel).each(function() {
			const sliderWidth = $(this).width();
			swiper.params.slidesOffsetBefore = (sliderWidth > Breakpoints.lg) ? (sliderWidth - Breakpoints.lg) / 2 : 0;
			swiper.update();
		});
	}

	$('.reviews-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				observer: true,
				slidesPerView: 1,
				centerInsufficientSlides: true,
				modules: [Navigation],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
				},
			});
		}
	});

	$('.product-carousel-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				slidesPerView: 1.1,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
				centerInsufficientSlides: true,
				modules: [Navigation, Scrollbar],

				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				scrollbar: {
					el: '.swiper-scrollbar',
					draggable: true,
				},
				breakpoints: {
					768: {
						slidesPerView: 2.1,
					},
					1024: {
						slidesPerView: 2.5,
					},
					1280: {
						slidesPerView: 3.2,
					},
					1600: {
						slidesPerView: 3.6,
					},
					1920: {
						slidesPerView: 4.5,
					},
				},
			});

			get_carousel_offset(this, swiper);
			$Elements.window.on(Events.resize, () => get_carousel_offset(this, swiper));
		}
	});

	$('.programme-images-carousel .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				observer: true,
				slidesPerView: 'auto',
				modules: [Pagination, Autoplay],
				autoplay: {
					delay: 3000,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					type: 'progressbar',
				},
			});
		}
	});

	$('.featured-images-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				slidesPerView: 'auto',
				modules: [Pagination, Autoplay],
				autoplay: {
					delay: 3000,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					type: 'progressbar',
				},
			});
		}
	});

	$('.post-list-section .section-carousel, .fake-products-section  .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				slidesPerView: 'auto',
				centerInsufficientSlides: true,
				modules: [Navigation, Scrollbar],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				scrollbar: {
					el: '.swiper-scrollbar',
					draggable: true,
				},
			});
		}
	});

	$('.text-image-slider-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				observer: true,
				slidesPerView: 1,
				slidesOffsetBefore: 0,
				slidesOffsetAfter: 0,
				modules: [Navigation, Scrollbar, Autoplay],
				autoHeight: true,
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
				scrollbar: {
					el: '.swiper-scrollbar',
					draggable: true,
				},
			});
		}
	});

	$('.single-team-section .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				observer: true,
				slidesPerView: 'auto',
				modules: [Pagination, Autoplay],
				autoplay: {
					delay: 3000,
				},
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true,
					type: 'progressbar',
				},
			});
		}
	});
});
